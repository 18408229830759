import { Component, OnInit } from '@angular/core';

import { TableColumn } from '../../../core/models/Table';
import { TableService } from '../../../core/services/table.service';

import { GPoint } from './../../../core/models/GPoint';
import { ClientsService } from './../../../core/services/clients.service';

@Component({
    selector: 'app-g-points',
    templateUrl: './g-points.component.html',
    styleUrls: ['./g-points.component.less'],
})
export class GPointsComponent implements OnInit {
    public gpoints: GPoint[] = [];
    public error = false;
    public loading = true;
    public columns: TableColumn[];

    constructor(private cs: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.gpoints = await this.cs.loadClientGPoints();
            this.columns = this.tableService.config.CLIENT.DETAIL.G_POINTS;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.error = true;
        }
    }
}
