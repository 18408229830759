import { Component, OnInit } from '@angular/core';

import { UserProfile } from './../../../core/models/UserProfile';
import { ClientsService } from './../../../core/services/clients.service';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.less'],
})
export class OverviewComponent implements OnInit {
    client: UserProfile;

    constructor(private cs: ClientsService) {}

    public ngOnInit() {
        this.client = this.cs.client;
    }
}
