import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chart-label',
    templateUrl: './chart-label.component.html',
    styleUrls: ['./chart-label.component.less'],
})
export class ChartLabelComponent {
    @Input() title: string;
    @Input() value: string;
}
