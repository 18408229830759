import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-outlet',
    templateUrl: './outlet.component.html',
    styleUrls: ['./outlet.component.less'],
})
export class OutletComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
