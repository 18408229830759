import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { ChartsModule } from 'ng2-charts';

import { AppRoutingModule } from '../app-routing.module';

import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { ChartLabelComponent } from './components/chart-label/chart-label.component';
import { ClientInfoComponent } from './components/client-info/client-info.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { InfoLabelComponent } from './components/info-label/info-label.component';
import { InteractionLogComponent } from './components/interaction-log/interaction-log.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LogoComponent } from './components/logo/logo.component';
import { PageHeadingComponent } from './components/page-heading/page-heading.component';
import { ProfileInfoComponent } from './components/profile-info/profile-info.component';
import { PurchasesStatsComponent } from './components/purchases-stats/purchases-stats.component';
import { TableComponent } from './components/table/table.component';

const COMONENTS = [
    LayoutComponent,
    LogoComponent,
    TableComponent,
    ClientInfoComponent,
    InteractionLogComponent,
    DoughnutChartComponent,
    BarChartComponent,
    PurchasesStatsComponent,
    ChartLabelComponent,
    InfoLabelComponent,
    ProfileInfoComponent,
    PageHeadingComponent,
];

@NgModule({
    declarations: [...COMONENTS],
    exports: [
        LayoutComponent,
        LogoComponent,
        TranslateModule,
        NgZorroAntdModule,
        FormsModule,
        ReactiveFormsModule,
        ...COMONENTS,
    ],
    imports: [
        CommonModule,
        NgZorroAntdModule,
        TranslateModule,
        AppRoutingModule,
        ChartsModule,
        FormsModule,
        ReactiveFormsModule,
    ],
})
export class SharedModule {}
