import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from './../shared/shared.module';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';
import { ShopsRoutingModule } from './shops-routing.module';

@NgModule({
    declarations: [OutletComponent, ListComponent],
    imports: [CommonModule, SharedModule, ShopsRoutingModule],
})
export class ShopsModule {}
