import { Injectable } from '@angular/core';

import { UserProfile } from '../../core/models/UserProfile';
import { UserMapperService } from '../mappers/user-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private userProfile: UserProfile;

    get user() {
        return this.userProfile;
    }

    set user(value: UserProfile) {
        this.userProfile = value;
    }

    constructor(private ums: UserMapperService) {}

    public login(email: string, password: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.user = await this.ums.login(email, password);
                resolve();
            } catch (e) {
                reject(e);
            }
        });
    }
}
