import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserProfile } from '../../core/models/UserProfile';

import { ClientsService } from './../../core/services/clients.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit, OnDestroy {
    static tabs = {
        overview: 0,
        transactions: 1,
        interactions: 2,
        vouchers: 3,
        segments: 4,
        'g-points': 5,
        wishlist: 6,
    };

    public tabIntex = 1;
    public client: UserProfile;

    private activeRouteSubscription: Subscription;
    private id: string;

    constructor(
        private route: ActivatedRoute,
        private cs: ClientsService,
        private router: Router
    ) {}

    public ngOnInit() {
        this.activeRouteSubscription = this.route.params.subscribe(async params => {
            this.id = params.id;
            this.client = await this.cs.loadClient(this.id);
        });
    }

    public ngOnDestroy() {
        this.activeRouteSubscription.unsubscribe();
    }

    public handleTabClick(location: string) {
        this.router.navigate(['clients/detail/' + this.id + '/' + location]);
    }
}
