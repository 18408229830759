import { Component, Input } from '@angular/core';

import { UserProfile } from './../../../core/models/UserProfile';

@Component({
    selector: 'app-client-info',
    templateUrl: './client-info.component.html',
    styleUrls: ['./client-info.component.less'],
})
export class ClientInfoComponent {
    @Input() client: UserProfile;
}
