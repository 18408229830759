import { Injectable } from '@angular/core';
import * as faker from 'faker';

import { UserMapperService } from '../mappers/user-mapper.service';
import { Interaction } from '../models/Interaction';
import { Segment } from '../models/Segment';
import { UserProfile } from '../models/UserProfile';

import { GPoint } from './../models/GPoint';
import { Token } from './../models/Token';

@Injectable({
    providedIn: 'root',
})
export class ClientsService {
    private clientsData: UserProfile[];
    private clientData: UserProfile;

    get clients() {
        return this.clientsData;
    }

    set clients(value: UserProfile[]) {
        this.clientsData = value;
    }

    get client() {
        return this.clientData;
    }

    constructor(private ums: UserMapperService) {}

    public loadClients(): Promise<UserProfile[]> {
        return new Promise<UserProfile[]>(async (resolve, reject) => {
            try {
                this.clients = await this.ums.getUsers();
                resolve(this.clients);
            } catch (e) {
                reject(e);
            }
        });
    }

    public loadClient(id: string): Promise<UserProfile> {
        return new Promise<UserProfile>(async (resolve, reject) => {
            try {
                this.clientData = await this.ums.getUser(id);
                resolve(this.clientData);
            } catch (e) {
                reject(e);
            }
        });
    }

    public loadClientInteractions(): Promise<Interaction[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(() => ({
                    id: faker.random.uuid(),
                    date: faker.date.past().toString(),
                    type: faker.random.word(),
                    price: `${faker.random.number()} EUR`,
                    source: faker.random.arrayElement([
                        'CRM System',
                        'E-shop',
                        'Bratislava eurovea',
                    ]),
                    detail: faker.random.words(),
                }));
            resolve(mock);
        });
    }

    public loadClientVouchers(): Promise<Token[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(() => ({
                    id: faker.random.uuid(),
                    name: faker.random.arrayElement([
                        '10% Voucher',
                        'Summer 10%',
                        '20% Voucher',
                        '50% Voucher',
                    ]),
                    acquired: faker.date.past().toString(),
                    expiration: faker.date.past().toString(),
                    value: `-${faker.random.number({ min: 0, max: 70 })}%`,
                    origin: faker.random.arrayElement([
                        'CRM System',
                        'E-shop',
                        'Bratislava eurovea',
                    ]),
                }));
            resolve(mock);
        });
    }

    public loadClientSegments(): Promise<Segment[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            name: faker.random.arrayElement([
                                'Gold memebership',
                                'VIP membership',
                                'T shirt maniac',
                            ]),
                            clients: faker.random.number({ max: 200 }).toString(),
                        } as Segment)
                );
            resolve(mock);
        });
    }

    public loadClientGPoints(): Promise<GPoint[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            action: faker.random.arrayElement([
                                'Purchase',
                                '50G for vouchers',
                                '92G for vouchers 40%',
                            ]),
                            amount: `${faker.random.arrayElement(['-', '+'])} ${faker.random.number(
                                { max: 100 }
                            )}`,
                            date: faker.date.past().toString(),
                            place: faker.random.arrayElement([
                                'CRM System',
                                'E-shop',
                                'Bratislava eurovea',
                            ]),
                            balance: faker.random.number({ max: 100 }).toString(),
                            transactionId: faker.random.uuid(),
                        } as GPoint)
                );
            resolve(mock);
        });
    }

    public loadClientWishlist(): Promise<any[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(() => ({
                    id: faker.random.uuid(),
                    name: faker.random.arrayElement([
                        'Blejzer',
                        'Motorkárska bunda',
                        'Bodkovaná sukňa',
                    ]),
                    code: faker.random.uuid(),
                    price: `${faker.random.number()} EUR`,
                    tags: undefined,
                }));
            resolve(mock);
        });
    }
}
