import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

import { Segment } from '../../core/models/Segment';
import { TableColumn } from '../../core/models/Table';
import { TableService } from '../../core/services/table.service';

import { SegmentsService } from './../../core/services/segments.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public segments: Segment[] = [];
    public error = false;
    public loading = true;
    public columns: TableColumn[];
    public modal: NzModalRef;
    public importSegmentForm: FormGroup;

    constructor(
        private sc: SegmentsService,
        private tableService: TableService,
        private router: Router,
        private modalService: NzModalService,
        private fb: FormBuilder
    ) {}

    public async ngOnInit() {
        try {
            this.importSegmentForm = this.fb.group({
                name: '',
                description: '',
            });
            this.segments = await this.sc.loadSegments();
            this.columns = this.tableService.config.SEGMENTS.LIST;
            this.loading = false;
        } catch (e) {
            this.error = true;
            this.loading = false;
            console.error(e);
        }
    }

    public handleCreateClick() {
        this.router.navigate(['segments/create']);
    }

    public handleCloseModalClick() {
        this.modal.close();
    }

    public handleImportSegmentClick(
        tplTitle: TemplateRef<{}>,
        tplContent: TemplateRef<{}>,
        tplFooter: TemplateRef<{}>
    ) {
        this.modal = this.modalService.create({
            nzTitle: tplTitle,
            nzContent: tplContent,
            nzFooter: tplFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => console.log('Click ok'),
        });
    }

    public handleModalSaveClick() {
        console.log(this.importSegmentForm.value);
        this.handleCloseModalClick();
    }

    public handleSegmentClick({ id }: Segment) {
        this.router.navigate(['segments/detail', id]);
    }
}
