import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ClientRoutingModule } from './client-routing.module';
import { DetailComponent } from './detail/detail.component';
import { GPointsComponent } from './detail/g-points/g-points.component';
import { InteractionsComponent } from './detail/interactions/interactions.component';
import { OverviewComponent } from './detail/overview/overview.component';
import { SegmentsComponent } from './detail/segments/segments.component';
import { VouchersComponent } from './detail/vouchers/vouchers.component';
import { WishlistComponent } from './detail/wishlist/wishlist.component';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';

@NgModule({
    declarations: [
        ListComponent,
        DetailComponent,
        OutletComponent,
        OverviewComponent,
        InteractionsComponent,
        VouchersComponent,
        SegmentsComponent,
        GPointsComponent,
        WishlistComponent,
    ],
    imports: [CommonModule, ClientRoutingModule, SharedModule],
})
export class ClientsModule {}
