import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Config } from '../models/Config';

@Injectable({
    providedIn: 'root',
})
export class ConfigService {
    private configuration: Config = {
        protocol: environment.protocol,
        host: environment.host,
        port: environment.port,
        terminalId: environment.terminalId,
        jwt: '',
    };

    get config(): Config {
        return this.configuration;
    }

    get baseURL(): string {
        return `${this.configuration.protocol}://${this.configuration.host}:${
            this.configuration.port
        }`;
    }

    getConfig(): Config {
        return this.configuration;
    }
}
