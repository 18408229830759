import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BenefitDiscountFormValues } from './../../../core/models/Forms';

@Component({
    selector: 'app-count-discount-form',
    templateUrl: './count-discount-form.component.html',
    styleUrls: ['./count-discount-form.component.less'],
})
export class CountDiscountFormComponent implements OnInit {
    @Output()
    public valuesChange = new EventEmitter<BenefitDiscountFormValues>();
    public form: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    public ngOnInit() {
        this.form = this.formBuilder.group({
            name: '',
            countries: [],
            description: '',
            discount: '',
            validity: '',
            product: '',
            priority: '',
            registeredUsersOnly: false,
            includeDiscountedProducts: false,
        });

        this.form.valueChanges.subscribe(values => {
            this.valuesChange.emit(values);
        });
    }
}
