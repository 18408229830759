import { Component, OnInit } from '@angular/core';

import { TableColumn } from '../../../core/models/Table';
import { TableService } from '../../../core/services/table.service';

import { Interaction } from './../../../core/models/Interaction';
import { ClientsService } from './../../../core/services/clients.service';

@Component({
    selector: 'app-interactions',
    templateUrl: './interactions.component.html',
    styleUrls: ['./interactions.component.less'],
})
export class InteractionsComponent implements OnInit {
    public interactions: Interaction[] = [];
    public loading = true;
    public error = false;
    public columns: TableColumn[];

    constructor(private cs: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.interactions = await this.cs.loadClientInteractions();
            this.columns = this.tableService.config.CLIENT.DETAIL.INTERACTIONS;
            this.loading = false;
        } catch (e) {
            this.error = true;
            console.error(e);
        }
    }
}
