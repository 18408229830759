import { Injectable } from '@angular/core';

import { ProductMapperService } from './../mappers/product-mapper.service';
import { Product } from './../models/Product';

@Injectable({
    providedIn: 'root',
})
export class ProductsService {
    private productsData: Product[];

    get products() {
        return this.productsData;
    }

    set products(value: Product[]) {
        this.productsData = value;
    }

    constructor(private pms: ProductMapperService) {}

    loadProducts(): Promise<Product[]> {
        return new Promise(async (resolve, reject) => {
            try {
                const rawProducts = await this.pms.getProducts({ $limit: 200, type: 'PRODUCT' });
                const transformedProducts: Product[] = rawProducts.map(rawProduct => ({
                    id: rawProduct.id,
                    name: rawProduct.name.sk,
                    code: rawProduct.extIdentifiers.gate.id,
                    color: rawProduct.extIdentifiers.gate.color,
                    openingPrice:
                        rawProduct.basePrices['SK'].price + rawProduct.basePrices['SK'].currency,
                    actualPrice: rawProduct.prices['SK'].price + rawProduct.prices['SK'].currency,
                    currency: rawProduct.prices['CZ'].price + rawProduct.prices['CZ'].currency,
                    type: '-',
                    category: '-',
                    group: '-',
                    tags: '-',
                }));
                this.productsData = transformedProducts;
                resolve(this.productsData);
            } catch (e) {
                reject(e);
            }
        });
    }
}
