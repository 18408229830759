import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-interaction-log',
    templateUrl: './interaction-log.component.html',
    styleUrls: ['./interaction-log.component.less'],
})
export class InteractionLogComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
