import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { BenefitDiscountFormValues } from './../../../core/models/Forms';

@Component({
    selector: 'app-price-discount-form',
    templateUrl: './price-discount-form.component.html',
    styleUrls: ['./price-discount-form.component.less'],
})
export class PriceDiscountFormComponent implements OnInit {
    @Output()
    public valuesChanges = new EventEmitter<BenefitDiscountFormValues>();
    public form: FormGroup;

    constructor(private formBuilder: FormBuilder) {}

    public ngOnInit() {
        this.form = this.formBuilder.group({
            name: '',
            countries: [],
            description: '',
            discount: '',
            validity: '',
            product: '',
            priority: '',
            registeredUsersOnly: false,
            includeDiscountedProducts: false,
        });

        this.form.valueChanges.subscribe(values => {
            this.valuesChanges.emit(values);
        });
    }
}
