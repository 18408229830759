import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

import { TableColumn } from '../../core/models/Table';
import { TableService } from '../../core/services/table.service';

import { Shop } from './../../core/models/Shop';
import { ShopsService } from './../../core/services/shops.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public error = false;
    public loading = true;
    public shops: Shop[] = [];
    public columns: TableColumn[];
    public createModal: NzModalRef;
    public shopForm: FormGroup;

    constructor(
        private shopService: ShopsService,
        private tableService: TableService,
        private modalService: NzModalService,
        private fb: FormBuilder
    ) {}

    public async ngOnInit() {
        try {
            this.shopForm = this.fb.group({
                name: '',
                code: '',
                address: '',
                countryCode: '',
                phone: '',
            });
            this.shops = await this.shopService.loadShops();
            this.columns = this.tableService.config.SHOPS.LIST;
            this.loading = false;
        } catch (e) {
            this.error = true;
            this.loading = false;
            console.error(e);
        }
    }

    public handleCreateShopClick(
        tplTitle: TemplateRef<{}>,
        tplContent: TemplateRef<{}>,
        tplFooter: TemplateRef<{}>
    ) {
        this.createModal = this.modalService.create({
            nzTitle: tplTitle,
            nzContent: tplContent,
            nzFooter: tplFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => console.log('Click ok'),
        });
    }

    public handleCloseShopModal() {
        this.createModal.close();
    }

    public handleSaveShopClick() {
        console.log(this.shopForm.value);
    }
}
