import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-purchases-stats',
    templateUrl: './purchases-stats.component.html',
    styleUrls: ['./purchases-stats.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class PurchasesStatsComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
