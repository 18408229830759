import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-info-label',
    templateUrl: './info-label.component.html',
    styleUrls: ['./info-label.component.less'],
})
export class InfoLabelComponent {
    @Input() label: string;
    @Input() value: string;
    @Input() alignment = 'left';
    @Input() size = 'default';
}
