import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config';
import { Transaction, TransactionEntryRaw, TransactionRaw } from '../models/Transaction';
import { ConfigService } from '../services/config.service';

import { TransactionEntry } from './../models/Transaction';

enum URLPaths {
    get = '/transactions',
}

@Injectable({
    providedIn: 'root',
})
export class TransactionMapperService extends Config {
    constructor(private http: HttpClient, private config: ConfigService) {
        super();
    }

    private transactionEntryTransform(raw: TransactionEntryRaw): TransactionEntry {
        return {
            color: '',
            size: '',
            note: '',
            name: raw.product.name,
            price: raw.amount.toString() + raw.currency,
        };
    }

    private transactionTransform(raw: TransactionRaw): Transaction {
        return {
            id: raw._id,
            client: raw.owner,
            date: raw.createdAt,
            price: raw.amount + raw.currency,
            items: raw.entries ? raw.entries.length.toString() : '',
            channel: raw.origin,
            entries: raw.entries
                ? raw.entries.map(entry => this.transactionEntryTransform(entry))
                : [],
        };
    }

    public getTransactions(query): Promise<Transaction[]> {
        return new Promise((resolve, reject) => {
            this.http
                .request('GET', `${this.config.baseURL}${URLPaths.get}`, {
                    headers: this.HTTP_HEADER,
                    withCredentials: true,
                    params: query,
                })
                .subscribe(
                    async (response: TransactionRaw[]) => {
                        resolve((await response).map(raw => this.transactionTransform(raw)));
                    },
                    err => reject(err)
                );
        });
    }

    public getTransaction(id: string): Promise<Transaction> {
        return new Promise<Transaction>((resolve, reject) => {
            this.http
                .request<Promise<TransactionRaw>>(
                    'GET',
                    `${this.config.baseURL}${URLPaths.get}/${id}`,
                    {
                        headers: this.HTTP_HEADER,
                        withCredentials: true,
                    }
                )
                .subscribe(
                    async response => {
                        resolve(this.transactionTransform(await response));
                    },
                    err => reject(err)
                );
        });
    }
}
