import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DetailComponent } from './detail/detail.component';
import { GPointsComponent } from './detail/g-points/g-points.component';
import { InteractionsComponent } from './detail/interactions/interactions.component';
import { OverviewComponent } from './detail/overview/overview.component';
import { SegmentsComponent } from './detail/segments/segments.component';
import { VouchersComponent } from './detail/vouchers/vouchers.component';
import { WishlistComponent } from './detail/wishlist/wishlist.component';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';

const routes: Routes = [
    {
        path: 'clients',
        component: OutletComponent,
        children: [
            { path: '', redirectTo: 'list', pathMatch: 'full' },
            { path: 'list', component: ListComponent },
            {
                path: 'detail/:id',
                component: DetailComponent,
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    { path: 'overview', component: OverviewComponent },
                    { path: 'interactions', component: InteractionsComponent },
                    { path: 'vouchers', component: VouchersComponent },
                    { path: 'segments', component: SegmentsComponent },
                    { path: 'g-points', component: GPointsComponent },
                    { path: 'wishlist', component: WishlistComponent },
                ],
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ClientRoutingModule {}
