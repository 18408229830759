import { Component, OnInit } from '@angular/core';

import { TableColumn } from '../../../core/models/Table';
import { ClientsService } from '../../../core/services/clients.service';
import { TableService } from '../../../core/services/table.service';

import { Product } from './../../../core/models/Product';

@Component({
    selector: 'app-wishlist',
    templateUrl: './wishlist.component.html',
    styleUrls: ['./wishlist.component.less'],
})
export class WishlistComponent implements OnInit {
    public wishlist: Product[] = [];
    public loading = true;
    public columns: TableColumn[];
    public error = false;

    constructor(private cs: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.wishlist = await this.cs.loadClientWishlist();
            this.columns = this.tableService.config.CLIENT.DETAIL.WISHLIST;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.error = true;
        }
    }
}
