import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

import { ModalSizes } from '../../core/models/Modal';
import { TableService } from '../../core/services/table.service';

import { Benefit, BenefitType as BenefitTypes } from './../../core/models/Benefit';
import { TableColumn } from './../../core/models/Table';
import { BenefitsService } from './../../core/services/benefits.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class ListComponent implements OnInit {
    public types = BenefitTypes;
    public selectedType: BenefitTypes;
    public error = false;
    public loading = true;
    public benefits: Benefit[] = [];
    public columns: TableColumn[];

    public selectTypeModal: NzModalRef;
    public countDiscountModal: NzModalRef;
    public percentageDiscountModal: NzModalRef;
    public priceDiscountDiscountModal: NzModalRef;

    @ViewChild('percenatageDiscountModalTitle')
    private percenatageDiscountModalTitle: TemplateRef<{}>;
    @ViewChild('percenatageDiscountModalContent')
    private percenatageDiscountModalContent: TemplateRef<{}>;
    @ViewChild('percenatageDiscountModalFooter')
    private percenatageDiscountModalFooter: TemplateRef<{}>;

    @ViewChild('countDiscountModalTitle')
    private countDiscountModalTitle: TemplateRef<{}>;
    @ViewChild('countDiscountModalContent')
    private countDiscountModalContent: TemplateRef<{}>;
    @ViewChild('countDiscountModalFooter')
    private countDiscountModalFooter: TemplateRef<{}>;

    @ViewChild('priceDiscountModalTitle')
    private priceDiscountModalTitle: TemplateRef<{}>;
    @ViewChild('priceDiscountModalContent')
    private priceDiscountModalContent: TemplateRef<{}>;
    @ViewChild('priceDiscountModalFooter')
    private priceDiscountModalFooter: TemplateRef<{}>;

    constructor(
        private benefitService: BenefitsService,
        private tableService: TableService,
        private modalService: NzModalService,
        private router: Router
    ) {}

    public async ngOnInit() {
        try {
            this.benefits = await this.benefitService.loadBenefits();
            this.columns = this.tableService.config.BENEFITS.LIST;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.error = e;
            console.error(e);
        }
    }

    public handleCreateClick(title: TemplateRef<{}>, content: TemplateRef<{}>) {
        this.selectTypeModal = this.modalService.create({
            nzTitle: title,
            nzContent: content,
            nzFooter: null,
        });
    }

    public handleTypeSelectClick(type: BenefitTypes) {
        this.selectTypeModal.close();
        setTimeout(() => {
            if (type === BenefitTypes.PercentageDiscount) {
                this.selectedType = type;
                this.handleOpenPercentageModal();
            } else if (type === BenefitTypes.PriceDiscount) {
                this.selectedType = type;
                this.handleOpenPriceModal();
            } else if (type === BenefitTypes.OnePlusOne) {
                this.selectedType = type;
                this.handleOpenCountModal();
            } else if (type === BenefitTypes.TwoPlusOne) {
                this.selectedType = type;
                this.handleOpenCountModal();
            }
        }, 300);
    }

    public handleOpenPercentageModal() {
        this.percentageDiscountModal = this.modalService.create({
            nzTitle: this.percenatageDiscountModalTitle,
            nzContent: this.percenatageDiscountModalContent,
            nzFooter: this.percenatageDiscountModalFooter,
            nzWidth: ModalSizes.Big,
        });
    }

    public handleOpenPriceModal() {
        this.priceDiscountDiscountModal = this.modalService.create({
            nzTitle: this.priceDiscountModalTitle,
            nzContent: this.priceDiscountModalContent,
            nzFooter: this.priceDiscountModalFooter,
            nzWidth: ModalSizes.Big,
        });
    }

    public handleOpenCountModal() {
        this.countDiscountModal = this.modalService.create({
            nzTitle: this.countDiscountModalTitle,
            nzContent: this.countDiscountModalContent,
            nzFooter: this.countDiscountModalFooter,
            nzWidth: ModalSizes.Big,
        });
    }

    public handleClosePercentageModal() {
        this.percentageDiscountModal.close();
    }

    public handleClosePriceModal() {
        this.priceDiscountDiscountModal.close();
    }

    public handleCloseCountModal() {
        this.countDiscountModal.close();
    }

    public handleCountDiscountValuesChange(values) {
        console.log(values);
    }

    public handleBenefitClick(benefit: Benefit) {
        this.router.navigate(['benefits/detail', benefit.id]);
    }
}
