import { Component, OnInit } from '@angular/core';

import { TableColumn } from '../../core/models/Table';

import { Product } from './../../core/models/Product';
import { ProductsService } from './../../core/services/products.service';
import { TableService } from './../../core/services/table.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public products: Product[] = [];
    public loading = true;
    public error = false;
    public columns: TableColumn[];

    constructor(private ps: ProductsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.products = await this.ps.loadProducts();
            this.columns = this.tableService.config.PRODUCTS.LIST;
            this.loading = false;
        } catch (e) {
            this.error = e;
            this.loading = false;
            console.error(e);
        }
    }
}
