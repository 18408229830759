import { Injectable } from '@angular/core';
import * as faker from 'faker';

import { ProductMapperService } from './../mappers/product-mapper.service';
import { Segment } from './../models/Segment';

@Injectable({
    providedIn: 'root',
})
export class SegmentsService {
    constructor(private _pms: ProductMapperService) {}

    public loadSegment(_id: string): Promise<Segment> {
        return new Promise(async (resolve, _reject) => {
            const mock: Segment = {
                id: faker.random.uuid(),
                name: faker.random.arrayElement([
                    'Girls 14 - 25',
                    'Gold membership',
                    'Boys 20 - 25',
                ]),
                type: faker.random.arrayElement(['Dynamic', 'Imported', 'Static']),
                createdAt: faker.date.past().toString(),
                country: faker.random.arrayElement(['SK', 'CZ']),
                clients: faker.random.number({ max: 2000 }).toString(),
                emailsSent: faker.random.number({ max: 2000000 }).toString(),
                openRate: `${faker.random.number({ max: 100 }).toString()} %`,
                clickRate: `${faker.random.number({ max: 100 }).toString()} %`,
                unsubscribeRate: `${faker.random.number({ max: 100 }).toString()} %`,
                sms: faker.random.number({ max: 1000 }).toString(),
            };
            resolve(mock);
        });
    }

    public loadSegments(): Promise<Segment[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            name: faker.random.arrayElement([
                                'Girls 14 - 25',
                                'Gold membership',
                                'Boys 20 - 25',
                            ]),
                            type: faker.random.arrayElement(['Dynamic', 'Imported', 'Static']),
                            createdAt: faker.date.past().toString(),
                            country: faker.random.arrayElement(['SK', 'CZ']),
                            clients: faker.random.number({ max: 2000 }).toString(),
                            emailsSent: faker.random.number({ max: 2000000 }).toString(),
                            openRate: `${faker.random.number({ max: 100 }).toString()} %`,
                            clickRate: `${faker.random.number({ max: 100 }).toString()} %`,
                            unsubscribeRate: `${faker.random.number({ max: 100 }).toString()} %`,
                            sms: faker.random.number({ max: 1000 }).toString(),
                        } as Segment)
                );
            resolve(mock);
        });
    }
}
