import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create',
    templateUrl: './create.component.html',
    styleUrls: ['./create.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class CreateComponent implements OnInit {
    public segmentForm: FormGroup;
    public items: FormArray;

    get behaviours() {
        return this.segmentForm.get('behaviours') as FormArray;
    }

    public countries = [
        {
            value: 'zhejiang',
            label: 'Zhejiang',
            children: [
                {
                    value: 'hangzhou',
                    label: 'Hangzhou',
                    children: [
                        {
                            value: 'xihu',
                            label: 'West Lake',
                            isLeaf: true,
                        },
                    ],
                },
                {
                    value: 'ningbo',
                    label: 'Ningbo',
                    isLeaf: true,
                },
            ],
        },
    ];

    constructor(private router: Router, private fb: FormBuilder) {}

    public ngOnInit() {
        this.segmentForm = this.fb.group({
            name: '',
            description: '',
            countries: [''],
            type: '',
            profile: this.fb.group({
                gender: '',
                locations: '',
                age: this.fb.group({
                    min: '',
                    max: '',
                }),
            }),
            loyalty: this.fb.group({
                gPoints: this.fb.group({
                    min: '',
                    max: '',
                }),
                segment: '',
                wishlist: '',
                vouchers: '',
                communicationAgreements: '',
            }),
            behaviours: this.fb.array([this.createItem()]),
        });
    }

    public handleAddBehaviourClick(): void {
        this.behaviours.push(this.createItem());
    }

    public handleApplyFilterClick() {
        console.log(this.segmentForm.value);
    }

    public handleCancelClick() {
        this.router.navigate(['/segments/list']);
    }

    private createItem(): FormGroup {
        return this.fb.group({
            date: '',
            channels: '',
            vouchers: '',
            productCategories: [''],
            numberOfItems: this.fb.group({
                min: '',
                max: '',
            }),
            numberOfPurchases: this.fb.group({
                withoutFilter: this.fb.group({
                    min: '',
                    max: '',
                }),
                onSale: this.fb.group({
                    min: '',
                    max: '',
                }),
                vouchers: this.fb.group({
                    min: '',
                    max: '',
                }),
            }),
            amountOfPurchase: this.fb.group({
                withoutFilter: this.fb.group({
                    min: '',
                    max: '',
                }),
                onSale: this.fb.group({
                    min: '',
                    max: '',
                }),
                vouchers: this.fb.group({
                    min: '',
                    max: '',
                }),
            }),
        });
    }
}
