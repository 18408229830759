import { Component, OnInit } from '@angular/core';

import { TableColumn } from '../../../core/models/Table';
import { TableService } from '../../../core/services/table.service';

import { Segment } from './../../../core/models/Segment';
import { ClientsService } from './../../../core/services/clients.service';

@Component({
    selector: 'app-segments',
    templateUrl: './segments.component.html',
    styleUrls: ['./segments.component.less'],
})
export class SegmentsComponent implements OnInit {
    public segments: Segment[];
    public columns: TableColumn[];
    public loading = true;
    public error = false;

    constructor(private cs: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.segments = await this.cs.loadClientSegments();
            this.columns = this.tableService.config.CLIENT.DETAIL.SEGMENTS;
            this.loading = false;
        } catch (e) {
            console.error(e);
            this.loading = false;
            this.error = true;
        }
    }
}
