import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-profile-info',
    templateUrl: './profile-info.component.html',
    styleUrls: ['./profile-info.component.less'],
})
export class ProfileInfoComponent {
    user = {
        name: 'Samo Forus',
        role: 'Admin',
    };
}
