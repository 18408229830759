import {
    Component,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'app-page-heading',
    templateUrl: './page-heading.component.html',
    styleUrls: ['./page-heading.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class PageHeadingComponent implements OnInit {
    @ViewChild('templateTitle', { read: ViewContainerRef })
    public templateTitle: ViewContainerRef;
    @Input()
    public title: string | TemplateRef<{}>;
    @Input()
    public titleTemplate?: TemplateRef<{}>;
    @Input()
    public subpage = false;
    @Input()
    public extra: string;

    public ngOnInit() {
        if (this.titleTemplate) {
            this.templateTitle.createEmbeddedView(this.titleTemplate);
        }
    }
}
