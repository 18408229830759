import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config';
import { Product } from '../models/Product';

import { ConfigService } from './../services/config.service';

enum URLPaths {
    get = '/products',
}

@Injectable({
    providedIn: 'root',
})
export class ProductMapperService extends Config {
    constructor(private http: HttpClient, private config: ConfigService) {
        super();
    }

    getProducts(query) {
        return new Promise<any[]>((resolve, reject) => {
            this.http
                .request('GET', `${this.config.baseURL}${URLPaths.get}`, {
                    headers: this.HTTP_HEADER,
                    withCredentials: true,
                    params: query,
                })
                .subscribe(
                    (response: any[]) => {
                        resolve(response);
                    },
                    err => reject(err)
                );
        });
    }

    getproduct(id: string) {
        return new Promise<Product>((resolve, reject) => {
            this.http
                .request('GET', `${this.config.baseURL}${URLPaths.get}/${id}`, {
                    headers: this.HTTP_HEADER,
                    withCredentials: true,
                })
                .subscribe(
                    (response: Product) => {
                        resolve(response);
                    },
                    err => reject(err)
                );
        });
    }
}
