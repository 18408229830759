export interface Benefit {
    id: string;
    priority: string;
    name: string;
    validity: string;
    status: string;
    discount?: string;
    purchase?: string;
    applyFor?: string;
    product?: string;
    description?: string;
    country?: string;
}

export enum BenefitType {
    PercentageDiscount = 'percentageDiscount',
    PriceDiscount = 'priceDiscount',
    TwoPlusOne = 'twoPlusOneDiscount',
    OnePlusOne = 'onePlusOneDiscount',
}
