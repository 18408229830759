import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as faker from 'faker';

import { Segment } from '../../core/models/Segment';

import { TableColumn } from './../../core/models/Table';
import { UserProfile } from './../../core/models/UserProfile';
import { ClientsService } from './../../core/services/clients.service';
import { TableService } from './../../core/services/table.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit {
    public segment: Segment = {
        id: 'f;kj',
        name: 'Test segment',
        type: 'Imported',
        description: faker.lorem.paragraph(5),
        clients: '32',
        createdAt: 'Thu Jan 17 2019',
        sms: '390049',
        members: '30',
        emailsSent: '390840984',
        openRate: '9080',
        clickRate: '390309',
        unsubscribeRate: '39%',
    };

    public loading = true;
    public error = false;

    public clients: UserProfile[];
    public columns: TableColumn[];

    constructor(private clientsService: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.clients = await this.clientsService.loadClients();
            this.columns = this.tableService.config.CLIENT.LIST;
            this.loading = false;
        } catch (e) {
            this.error = e;
            console.error(e);
        }
        this.columns = this.tableService.config.CLIENT.LIST;
        this.loading = false;
    }
    public handleClientClick() {}
}
