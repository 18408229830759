import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    ColumnTypes,
    Sort,
    SortOrder,
    TableColumn,
    TableDataEntry,
} from '../../../core/models/Table';

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.less'],
})
export class TableComponent implements OnInit {
    @Input() public columns: TableColumn[];
    @Input() public data: TableDataEntry[];
    @Input() public key: string;

    @Output() public rowClick = new EventEmitter<boolean>();

    public scroll = {};
    public displayData: TableDataEntry[];

    public ngOnInit() {
        this.displayData = [...this.data];
    }

    public handleSort(sort: Sort) {
        if (sort.value && sort.key) {
            const { type } = this.columns.find(c => c.dataIndex === sort.key);
            if (type === ColumnTypes.String) {
                const sortedData = Array.from(this.data).sort((a, b) =>
                    this.stringSorter(a[sort.key], b[sort.key])
                );

                if (SortOrder.Ascend === sort.value) {
                    this.displayData = sortedData;
                } else {
                    this.displayData = sortedData.reverse();
                }
            } else if (type === ColumnTypes.Number) {
                const sortedData = Array.from(this.data).sort((a, b) =>
                    this.numberSorter(a[sort.key], b[sort.key])
                );

                if (SortOrder.Ascend === sort.value) {
                    this.displayData = sortedData;
                } else {
                    this.displayData = sortedData.reverse();
                }
            } else if (type === ColumnTypes.Date) {
                const sortedData = Array.from(this.data).sort((a, b) =>
                    this.dateSorter(a[sort.key], b[sort.key])
                );

                if (SortOrder.Ascend === sort.value) {
                    this.displayData = sortedData;
                } else {
                    this.displayData = sortedData.reverse();
                }
            }
        } else {
            this.displayData = [...this.data];
        }
    }

    public handleRowClick(row) {
        this.rowClick.emit(row);
    }

    public stringSorter(a: string, b: string) {
        return a.localeCompare(b);
    }

    public dateSorter(a: string, b: string) {
        return new Date(a).getTime() - new Date(b).getTime();
    }

    public numberSorter(a: string, b: string) {
        return Number(a) - Number(b);
    }
}
