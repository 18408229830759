import { Injectable } from '@angular/core';
import * as faker from 'faker';

import { Campaign } from './../models/Campaign';

@Injectable({
    providedIn: 'root',
})
export class CampaignsService {
    private campaignsData: Campaign[];

    get campaigns() {
        return this.campaignsData;
    }

    set campaigns(value: Campaign[]) {
        this.campaignsData = value;
    }

    constructor() {}

    public loadCampaigns(): Promise<Campaign[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            name: faker.random.arrayElement([
                                'Summer Sale 2019',
                                'Autunumn Sale 2099',
                            ]),
                            status: faker.random.arrayElement(['Scheduled', 'Sent']),
                            date: faker.date.past().toString(),
                            clients: faker.random.number({ max: 100000 }).toString(),
                            emailsSent: faker.random.number({ max: 100000 }).toString(),
                            openRate: faker.random.number({ max: 100000 }).toString(),
                            adjustRate: faker.random.number({ max: 100000 }).toString(),
                            segments: ['Summer Style, Vip'],
                        } as Campaign)
                );
            resolve(mock);
        });
    }
}
