import { Injectable } from '@angular/core';
import * as faker from 'faker';

import { Benefit } from './../models/Benefit';

@Injectable({
    providedIn: 'root',
})
export class BenefitsService {
    constructor() {}

    public loadBenefit(id: string) {
        return new Promise(async (resolve, _reject) => {
            const mock: Benefit = {
                id: faker.random.uuid(),
                name: faker.random.arrayElement([
                    '10% Voucher',
                    'Summer 10%',
                    '20% Voucher',
                    '50% Voucher',
                ]),
                validity: faker.date.past().toString(),
                priority: faker.random.number({ max: 10 }).toString(),
                status: faker.random.arrayElement(['Active', 'Not Active']),
            };
            resolve(mock);
        });
    }

    public loadBenefits(): Promise<Benefit[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            name: faker.random.arrayElement([
                                '10% Voucher',
                                'Summer 10%',
                                '20% Voucher',
                                '50% Voucher',
                            ]),
                            validity: faker.date.past().toString(),
                            priority: faker.random.number({ max: 10 }).toString(),
                            status: faker.random.arrayElement(['Active', 'Not Active']),
                            products: faker.random.arrayElement(['Shirt', 'Trousers']),
                        } as Benefit)
                );
            resolve(mock);
        });
    }
}
