import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { CreateComponent } from './create/create.component';
import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';
import { SegmentsRoutingModule } from './segments-routing.module';

@NgModule({
    declarations: [ListComponent, OutletComponent, CreateComponent, DetailComponent],
    imports: [CommonModule, SegmentsRoutingModule, SharedModule],
})
export class SegmentsModule {}
