import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Config } from '../config';
import { UserProfile } from '../models/UserProfile';

import { ConfigService } from './../services/config.service';

enum URLPaths {
    get = '/users',
    login = '/users/login',
}

@Injectable({
    providedIn: 'root',
})
export class UserMapperService extends Config {
    constructor(private http: HttpClient, private config: ConfigService) {
        super();
    }

    login(email: string, password: string) {
        return new Promise<UserProfile>((resolve, reject) => {
            this.http
                .request('POST', `${this.config.baseURL}${URLPaths.login}`, {
                    headers: this.HTTP_HEADER,
                    body: {
                        email,
                        password,
                    },
                    withCredentials: true,
                })
                .subscribe(
                    (response: UserProfile) => {
                        resolve(response);
                    },
                    err => reject(err)
                );
        });
    }

    getUsers() {
        return new Promise<UserProfile[]>((resolve, reject) => {
            this.http
                .request('GET', `${this.config.baseURL}${URLPaths.get}`, {
                    headers: this.HTTP_HEADER,
                    withCredentials: true,
                })
                .subscribe(
                    (response: UserProfile[]) => {
                        resolve(response);
                    },
                    err => reject(err)
                );
        });
    }

    getUser(id) {
        return new Promise<UserProfile>((resolve, reject) => {
            this.http
                .request('GET', `${this.config.baseURL}${URLPaths.get}/${id}`, {
                    headers: this.HTTP_HEADER,
                    withCredentials: true,
                })
                .subscribe(
                    (response: UserProfile) => {
                        resolve(response);
                    },
                    err => reject(err)
                );
        });
    }
}
