import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent {
    title = 'admin-frontend';

    constructor(ts: TranslateService) {
        ts.setDefaultLang('en');
        ts.use('en');
    }
}
