import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';

import { TableColumn } from '../../core/models/Table';
import { TableService } from '../../core/services/table.service';

import { Campaign } from './../../core/models/Campaign';
import { CampaignsService } from './../../core/services/campaigns.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public error = false;
    public loading = true;
    public campaigns: Campaign[] = [];
    public columns: TableColumn[];
    public modal: NzModalRef;
    public campaignForm: FormGroup;
    public testCampaignForm: FormGroup;

    constructor(
        private campaignService: CampaignsService,
        private tableService: TableService,
        private modalService: NzModalService,
        private fb: FormBuilder
    ) {}

    public async ngOnInit() {
        try {
            this.testCampaignForm = this.fb.group({
                number: '',
            });

            this.campaignForm = this.fb.group({
                name: '',
                message: '',
                segment: '',
            });

            this.campaigns = await this.campaignService.loadCampaigns();
            this.columns = this.tableService.config.CAMPAIGNS.LIST;
            this.loading = false;
        } catch (e) {
            this.error = e;
            this.loading = false;
        }
    }

    public handleCreateCampaignClick(
        tplTitle: TemplateRef<{}>,
        tplContent: TemplateRef<{}>,
        tplFooter: TemplateRef<{}>
    ) {
        this.modal = this.modalService.create({
            nzTitle: tplTitle,
            nzContent: tplContent,
            nzFooter: tplFooter,
            nzMaskClosable: false,
            nzClosable: false,
            nzOnOk: () => console.log('Click ok'),
        });
    }

    public handleCloseModal() {
        this.modal.close();
    }

    public handleSendCampaignClick() {
        console.log(this.campaignForm.value);
        this.handleCloseModal();
    }

    public handleSendTestSMSClick() {
        console.log(this.testCampaignForm.value);
        this.testCampaignForm.reset();
    }
}
