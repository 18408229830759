import { Component, OnInit } from '@angular/core';

import { ClientsService } from '../../../core/services/clients.service';

import { TableColumn } from './../../../core/models/Table';
import { Token } from './../../../core/models/Token';
import { TableService } from './../../../core/services/table.service';

@Component({
    selector: 'app-vouchers',
    templateUrl: './vouchers.component.html',
    styleUrls: ['./vouchers.component.less'],
})
export class VouchersComponent implements OnInit {
    public vouchers: Token[] = [];
    public error = false;
    public columns: TableColumn[];
    public loading = true;

    constructor(private cs: ClientsService, private tableService: TableService) {}

    public async ngOnInit() {
        try {
            this.vouchers = await this.cs.loadClientVouchers();
            this.columns = this.tableService.config.CLIENT.DETAIL.VOUCHERS;
            this.loading = false;
        } catch (e) {
            this.loading = false;
            this.error = true;
        }
    }
}
