export interface TableConfig {
    CLIENT: {
        LIST: TableColumn[];
        DETAIL: {
            INTERACTIONS: TableColumn[];
            VOUCHERS: TableColumn[];
            SEGMENTS: TableColumn[];
            G_POINTS: TableColumn[];
            WISHLIST: TableColumn[];
        };
    };
    SEGMENTS: {
        LIST: TableColumn[];
    };
    PRODUCTS: {
        LIST: TableColumn[];
    };
    SHOPS: {
        LIST: TableColumn[];
    };
    BENEFITS: {
        LIST: TableColumn[];
    };
    TRANSACTIONS: {
        LIST: TableColumn[];
        ENTRIES: TableColumn[];
    };
    CAMPAIGNS: {
        LIST: TableColumn[];
    };
}

export enum ColumnTypes {
    String = 'string',
    Number = 'number',
    Date = 'date',
}

export interface TableColumn {
    title: string;
    dataIndex: string;
    type: string;
    align?: 'left' | 'right' | 'center';
    width?: string | number;
}

export interface TableDataEntry {
    [key: string]: string;
}

export enum SortOrder {
    Ascend = 'ascend',
    Descend = 'descend',
}

export interface Sort {
    key: string;
    value: SortOrder;
}
