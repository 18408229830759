import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { TableColumn } from '../../core/models/Table';
import { TableService } from '../../core/services/table.service';

import { Transaction } from './../../core/models/Transaction';
import { TransactionsService } from './../../core/services/transactions.service';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit, OnDestroy {
    private activeRouteSubscription: Subscription;
    private id: string;
    public transaction: Transaction;
    public columns: TableColumn[];
    public error = false;
    public loading = true;

    constructor(
        private route: ActivatedRoute,
        private trs: TransactionsService,
        private tableService: TableService
    ) {}

    public ngOnInit() {
        this.columns = this.tableService.config.TRANSACTIONS.ENTRIES;
        this.activeRouteSubscription = this.route.params.subscribe(async params => {
            this.id = params.id;
            try {
                this.transaction = await this.trs.loadTransaction(this.id);
            } catch (e) {
                console.error(e);
                this.loading = false;
                this.error = true;
            }
        });
    }

    public ngOnDestroy() {
        this.activeRouteSubscription.unsubscribe();
    }
}
