import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { UserService } from './../../core/services/user.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    error = false;

    constructor(private fb: FormBuilder, private us: UserService, private router: Router) {}

    ngOnInit(): void {
        this.loginForm = this.fb.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required]],
            remember: [true],
        });
    }

    async submitForm() {
        if (this.loginForm.valid) {
            const { username, password } = this.loginForm.value;
            try {
                await this.us.login(username, password);
                this.router.navigate(['/dashboard']);
            } catch (e) {
                if (e.status === 404) {
                    this.error = true;
                } else {
                    console.error(e);
                }
            }
        }
    }
}
