import { Injectable } from '@angular/core';

import { Transaction } from '../../core/models/Transaction';
import { TransactionMapperService } from '../mappers/transaction-mapper.service';

@Injectable({
    providedIn: 'root',
})
export class TransactionsService {
    private transactionsData: Transaction[];
    private transactionData: Transaction;

    get transactions() {
        return this.transactionsData;
    }

    set transactions(value: Transaction[]) {
        this.transactionsData = value;
    }

    get transaction() {
        return this.transactionData;
    }

    set transaction(value: Transaction) {
        this.transactionData = value;
    }

    constructor(private trs: TransactionMapperService) {}

    public loadTransactions(): Promise<Transaction[]> {
        return new Promise<Transaction[]>(async (resolve, reject) => {
            try {
                this.transactionsData = await this.trs.getTransactions({ $limit: 100 });
                resolve(this.transactionsData);
            } catch (e) {
                reject(e);
            }
        });
    }

    public loadTransaction(id: string): Promise<Transaction> {
        return new Promise<Transaction>(async (resolve, reject) => {
            try {
                this.transactionData = await this.trs.getTransaction(id);
                resolve(this.transactionData);
            } catch (e) {
                reject(e);
            }
        });
    }
}
