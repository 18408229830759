import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TableColumn } from './../../core/models/Table';
import { Transaction } from './../../core/models/Transaction';
import { TableService } from './../../core/services/table.service';
import { TransactionsService } from './../../core/services/transactions.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public transactions: Transaction[] = [];
    public loading = true;
    public error = false;
    public columns: TableColumn[];

    constructor(
        private router: Router,
        private trs: TransactionsService,
        private tableService: TableService
    ) {}

    public async ngOnInit() {
        try {
            this.transactions = await this.trs.loadTransactions();
            this.columns = this.tableService.config.TRANSACTIONS.LIST;
            this.loading = false;
        } catch (e) {
            this.error = true;
            this.loading = false;
            console.error(e);
        }
    }

    public handleTransactionClick(row) {
        this.router.navigate(['/transactions/detail', row.id]);
    }
}
