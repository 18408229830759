import { environment } from '../../../environments/environment';

export class Config {
    HTTP_HEADER = {
        Authorization: `Basic ${environment.basicAuthToken}`,
        'Content-Type': 'application/json',
    };
}

export const TABLES = {
    CLIENT: {
        LIST: [
            {
                title: 'Firstname',
                type: 'string',
                dataIndex: 'firstName',
                align: 'left',
            },
            {
                title: 'Lastname',
                type: 'string',
                dataIndex: 'lastName',
            },
            {
                title: 'Email',
                type: 'string',
                dataIndex: 'email',
            },
            {
                title: 'Gate id',
                type: 'string',
                dataIndex: 'id',
            },
            {
                title: 'Registration',
                type: 'date',
                dataIndex: 'createdAt',
            },
            {
                title: 'Country',
                type: 'date',
                dataIndex: 'country',
            },
            {
                title: 'Purchases',
                type: 'number',
                dataIndex: 'purchases',
            },
            {
                title: 'Last purchase',
                type: 'number',
                dataIndex: 'lastPurchase',
            },
            {
                title: 'Spent Total',
                type: 'number',
                dataIndex: 'spentTotal',
            },
            {
                title: 'G Points',
                type: 'string',
                dataIndex: 'gPoints',
            },
            {
                title: 'Segments',
                type: 'string',
                dataIndex: 'segments',
                align: 'right',
            },
        ],
        DETAIL: {
            INTERACTIONS: [
                {
                    title: 'Date',
                    type: 'string',
                    dataIndex: 'date',
                },
                {
                    title: 'Type',
                    type: 'string',
                    dataIndex: 'type',
                },
                {
                    title: 'Price',
                    type: 'string',
                    dataIndex: 'price',
                },
                {
                    title: 'Source',
                    type: 'string',
                    dataIndex: 'source',
                },
                {
                    title: 'Detail',
                    type: 'string',
                    dataIndex: 'detail',
                },
            ],
            VOUCHERS: [
                {
                    title: 'Name',
                    type: 'string',
                    dataIndex: 'name',
                    align: 'left',
                },
                {
                    title: 'Acquired',
                    type: 'string',
                    dataIndex: 'acquired',
                    align: 'left',
                },
                {
                    title: 'Expiration',
                    type: 'string',
                    dataIndex: 'expiration',
                    align: 'left',
                },
                {
                    title: 'Value',
                    type: 'string',
                    dataIndex: 'value',
                    align: 'left',
                },
                {
                    title: 'Origin',
                    type: 'string',
                    dataIndex: 'origin',
                    align: 'left',
                },
            ],
            SEGMENTS: [
                {
                    title: 'Name',
                    type: 'string',
                    dataIndex: 'name',
                    align: 'left',
                },
                {
                    title: 'Clients',
                    type: 'string',
                    dataIndex: 'clients',
                    align: 'left',
                },
            ],
            WISHLIST: [
                {
                    title: 'Name',
                    type: 'string',
                    dataIndex: 'name',
                    align: 'left',
                },
                {
                    title: 'Code',
                    type: 'string',
                    dataIndex: 'code',
                    align: 'left',
                },
                {
                    title: 'Price',
                    type: 'string',
                    dataIndex: 'price',
                    align: 'price',
                },
            ],
            G_POINTS: [
                {
                    title: 'Amount',
                    type: 'string',
                    dataIndex: 'amount',
                    align: 'left',
                },
                {
                    title: 'Date',
                    type: 'string',
                    dataIndex: 'date',
                    align: 'left',
                },
                {
                    title: 'Place',
                    type: 'string',
                    dataIndex: 'place',
                    align: 'left',
                },
                {
                    title: 'Balance',
                    type: 'balance',
                    dataIndex: 'balance',
                    align: 'left',
                },
                {
                    title: 'Transaction ID',
                    type: 'string',
                    dataIndex: 'transactionId',
                    align: 'left',
                },
            ],
        },
    },
    SEGMENTS: {
        LIST: [
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Clients',
                type: 'string',
                dataIndex: 'clients',
            },
            {
                title: 'Created at',
                type: 'date',
                dataIndex: 'createdAt',
            },
            {
                title: 'Country',
                type: 'string',
                dataIndex: 'country',
            },
            {
                title: 'Emails Sent',
                type: 'number',
                dataIndex: 'emailsSent',
            },
            {
                title: 'Open rate',
                type: 'number',
                dataIndex: 'openRate',
            },
            {
                title: 'Click rate',
                type: 'number',
                dataIndex: 'clickRate',
            },
            {
                title: 'Unsub rate',
                type: 'number',
                dataIndex: 'unsubscribeRate',
            },
            {
                title: 'SMS',
                type: 'number',
                dataIndex: 'sms',
            },
        ],
    },
    PRODUCTS: {
        LIST: [
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Code',
                type: 'string',
                dataIndex: 'code',
            },
            {
                title: 'Color',
                type: 'string',
                dataIndex: 'color',
            },
            {
                title: 'Opening price',
                type: 'string',
                dataIndex: 'openingPrice',
            },
            {
                title: 'Actual price',
                type: 'string',
                dataIndex: 'actualPrice',
            },
            {
                title: 'Currency',
                type: 'string',
                dataIndex: 'currency',
            },
            {
                title: 'Type',
                type: 'string',
                dataIndex: 'type',
            },
            {
                title: 'Group',
                type: 'string',
                dataIndex: 'group',
            },
            {
                title: 'Category',
                type: 'string',
                dataIndex: 'category',
            },
        ],
    },
    SHOPS: {
        LIST: [
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Code',
                type: 'string',
                dataIndex: 'code',
            },
            {
                title: 'Address',
                type: 'string',
                dataIndex: 'address',
            },
            {
                title: 'Country code',
                type: 'string',
                dataIndex: 'countryCode',
            },
            {
                title: 'Phone',
                type: 'string',
                dataIndex: 'phone',
            },
        ],
    },
    BENEFITS: {
        LIST: [
            {
                title: 'Priority',
                type: 'string',
                dataIndex: 'priority',
            },
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Validity',
                type: 'string',
                dataIndex: 'validity',
            },
            {
                title: 'Status',
                type: 'string',
                dataIndex: 'status',
            },
            {
                title: 'Products',
                type: 'string',
                dataIndex: 'products',
            },
        ],
    },
    TRANSACTIONS: {
        LIST: [
            {
                title: 'Transaction ID',
                type: 'string',
                dataIndex: 'id',
            },
            {
                title: 'Client',
                type: 'string',
                dataIndex: 'client',
            },
            {
                title: 'Date',
                type: 'string',
                dataIndex: 'date',
            },
            {
                title: 'Price',
                type: 'string',
                dataIndex: 'price',
            },
            {
                title: 'Items',
                type: 'string',
                dataIndex: 'items',
            },
            {
                title: 'Channel',
                type: 'string',
                dataIndex: 'channel',
            },
        ],
        ENTRIES: [
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Color',
                type: 'string',
                dataIndex: 'color',
            },
            {
                title: 'Size',
                type: 'string',
                dataIndex: 'size',
            },
            {
                title: 'Note',
                type: 'string',
                dataIndex: 'note',
            },
            {
                title: 'Price',
                type: 'string',
                dataIndex: 'price',
            },
        ],
    },
    CAMPAIGNS: {
        LIST: [
            {
                title: 'Name',
                type: 'string',
                dataIndex: 'name',
            },
            {
                title: 'Status',
                type: 'string',
                dataIndex: 'status',
            },
            {
                title: 'Date',
                type: 'string',
                dataIndex: 'date',
            },
            {
                title: 'Clients',
                type: 'string',
                dataIndex: 'clients',
            },
            {
                title: 'Emails sent',
                type: 'string',
                dataIndex: 'emailsSent',
            },
            {
                title: 'Open rate',
                type: 'string',
                dataIndex: 'openRate',
            },
            {
                title: 'Adjust rate',
                type: 'string',
                dataIndex: 'adjustRate',
            },
            {
                title: 'Segments',
                type: 'string',
                dataIndex: 'segments',
            },
        ],
    },
};
