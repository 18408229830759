import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';
import { TransactionsRoutingModule } from './transactions-routing.module';

@NgModule({
    declarations: [DetailComponent, ListComponent, OutletComponent],
    imports: [CommonModule, SharedModule, TransactionsRoutingModule],
})
export class TransactionsModule {}
