import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TableColumn } from '../../core/models/Table';
import { UserProfile } from '../../core/models/UserProfile';

import { ClientsService } from './../../core/services/clients.service';
import { TableService } from './../../core/services/table.service';

@Component({
    selector: 'app-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.less'],
})
export class ListComponent implements OnInit {
    public clients: UserProfile[];
    public loading = true;
    public error = false;

    public columns: TableColumn[];

    constructor(
        private cs: ClientsService,
        private router: Router,
        private tableService: TableService
    ) {}

    public async ngOnInit() {
        try {
            this.clients = await this.cs.loadClients();
            this.columns = this.tableService.config.CLIENT.LIST;
            this.loading = false;
        } catch (e) {
            this.error = e;
            console.error(e);
        }
    }

    public handleRowClick(row) {
        this.router.navigate(['/clients/detail', row.id]);
    }
}
