import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { en_US, NgZorroAntdModule, NZ_I18N } from 'ng-zorro-antd';
import {
    MESSAGE_FORMAT_CONFIG,
    TranslateMessageFormatCompiler,
} from 'ngx-translate-messageformat-compiler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { BenefitsModule } from './benefits/benefits.module';
import { CampaignsModule } from './campaigns/campaigns.module';
import { ClientsModule } from './clients/clients.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { ProductsModule } from './products/products.module';
import { SegmentsModule } from './segments/segments.module';
import { SharedModule } from './shared/shared.module';
import { ShopsModule } from './shops/shops.module';
import { TransactionsModule } from './transactions/transactions.module';

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/locales/', '.json');
}

registerLocaleData(en);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgZorroAntdModule,
        HttpClientModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        SharedModule,
        ClientsModule,
        TransactionsModule,
        ProductsModule,
        SegmentsModule,
        ShopsModule,
        CampaignsModule,
        BenefitsModule,
        AuthModule,
        DashboardModule,
    ],
    providers: [
        {
            provide: MESSAGE_FORMAT_CONFIG,
            useValue: { locales: ['en'] },
        },
        { provide: NZ_I18N, useValue: en_US },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
