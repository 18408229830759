import { Injectable } from '@angular/core';
import * as faker from 'faker';

import { Shop } from './../models/Shop';

@Injectable({
    providedIn: 'root',
})
export class ShopsService {
    constructor() {}

    public loadShops(): Promise<Shop[]> {
        return new Promise(async (resolve, _reject) => {
            const mock = Array(50)
                .fill(undefined)
                .map(
                    () =>
                        ({
                            id: faker.random.uuid(),
                            name: faker.random.arrayElement([
                                'Girls 14 - 25',
                                'Gold membership',
                                'Boys 20 - 25',
                            ]),
                            address: faker.address.streetName(),
                            code: faker.random.number().toString(),
                            countryCode: faker.random.arrayElement(['SK', 'CZ']),
                            phone: faker.phone.phoneNumber(),
                        } as Shop)
                );
            resolve(mock);
        });
    }
}
