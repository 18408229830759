import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.less'],
})
export class LogoComponent {
    @Input() type: 'LOGIN' | 'default' = 'default';
}
