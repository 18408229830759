import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from './../shared/shared.module';
import { BenefitsRoutingModule } from './benefits-routing.module';
import { TypeSelectComponent } from './components/type-select/type-select.component';
import { DetailComponent } from './detail/detail.component';
import { CountDiscountFormComponent } from './forms/count-discount-form/count-discount-form.component';
import { PercentageDiscountFormComponent } from './forms/percentage-discount-form/percentage-discount-form.component';
import { PriceDiscountFormComponent } from './forms/price-discount-form/price-discount-form.component';
import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';

const COMPONENTS = [
    ListComponent,
    OutletComponent,
    TypeSelectComponent,
    PriceDiscountFormComponent,
    PercentageDiscountFormComponent,
    CountDiscountFormComponent,
];

@NgModule({
    declarations: [...COMPONENTS, DetailComponent],
    imports: [CommonModule, SharedModule, BenefitsRoutingModule],
})
export class BenefitsModule {}
