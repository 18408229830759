import { Component, EventEmitter, Input, Output } from '@angular/core';

import { BenefitType } from './../../../core/models/Benefit';

@Component({
    selector: 'app-type-select',
    templateUrl: './type-select.component.html',
    styleUrls: ['./type-select.component.less'],
})
export class TypeSelectComponent {
    @Output() itemSelected = new EventEmitter<BenefitType>();

    public types = BenefitType;

    public handleItemClick(type: BenefitType) {
        this.itemSelected.emit(type);
    }
}
