import { Component, Input, OnInit } from '@angular/core';

interface Label {
    name: string;
    value: string;
}
@Component({
    selector: 'app-doughnut-chart',
    templateUrl: './doughnut-chart.component.html',
    styleUrls: ['./doughnut-chart.component.less'],
})
export class DoughnutChartComponent implements OnInit {
    @Input()
    public reverse = false;
    @Input()
    public labels: Label[] = [
        { name: 'Online purchases', value: '35%' },
        { name: 'Mobile purchases', value: '40%' },
        { name: 'Mail-Order Sales', value: '23%' },
    ];

    public cleanLabels;
    public data: number[] = [350, 450, 100];
    public type = 'doughnut';

    ngOnInit() {
        this.cleanLabels = this.omitLabelValues(this.labels);
    }

    public omitLabelValues(labels: Label[]): string[] {
        return labels.map(label => label.name);
    }
}
