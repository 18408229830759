import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ListComponent } from './list/list.component';
import { OutletComponent } from './outlet/outlet.component';
import { ProductsRoutingModule } from './products-routing';

@NgModule({
    declarations: [OutletComponent, ListComponent],
    imports: [CommonModule, ProductsRoutingModule, SharedModule],
})
export class ProductsModule {}
