import { Injectable } from '@angular/core';

import { TABLES } from '../config';

import { TableConfig } from './../models/Table';

@Injectable({
    providedIn: 'root',
})
export class TableService {
    private tableConfig: TableConfig;

    get config() {
        return this.tableConfig;
    }

    set config(config: TableConfig) {
        this.tableConfig = config;
    }

    constructor() {
        this.config = (TABLES as any) as TableConfig;
    }
}
