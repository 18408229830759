import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as faker from 'faker';

import { Benefit } from './../../core/models/Benefit';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.less'],
    encapsulation: ViewEncapsulation.None,
})
export class DetailComponent implements OnInit {
    public benefit: Benefit = {
        priority: '8',
        name: 'Benefit test',
        id: 'fkjlkfdj',
        validity: '12.4.2018 - 19.3.2019',
        discount: '10%',
        status: 'Active',
        product: 'T-shirt',
        purchase: 'More than 10 eur',
        applyFor: 'Most expensive',
        description: faker.lorem.paragraph(10),
        country: 'Slovakia',
    };

    constructor() {}

    public ngOnInit() {}

    public handleAssignClick() {}

    public handleEditClick() {}
}
